<template>
  <b-modal
    v-model="dialog"
    title="Edit Verified Stock Report"
    size="lg"
  >
    <div
      v-if="initialLoader"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>
    <b-alert
      variant="danger"
      :show="initialError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ initialError }}
        </p>
      </div>
    </b-alert>
    <b-form v-if="data && !initialLoader && !initialError">
      <b-table-simple
        responsive
        hover
        class="compact-table"
      >
        <b-tbody>
          <b-tr>
            <b-th> Date </b-th>
            <b-td> {{ formatedDate(data.date) }} </b-td>
            <b-th> Excel File </b-th>
            <b-td>
              <a
                v-if="data.excel"
                :href="data.excel"
                target="_blank"
              >
                <span>View File</span>
              </a>
              <span v-else>-</span>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <b-form-group label="Invoices">
        <b-table
          :items="data.invoices"
          responsive="sm"
          striped
          hover
          class="compact-table"
        />
      </b-form-group>

      <h6> Stock Report Details </h6>
      <b-table-simple
        bordered
        fixed
      >
        <b-tbody>
          <b-tr>
            <b-th> Verified PDF </b-th>
            <b-td>
              <span v-if="formData.verified_pdf">
                <a
                  :href="formData.verified_pdf"
                  target="_blank"
                >
                  <span>View File</span>
                </a>
                <b-button
                  v-if="!data.is_closed"
                  class="btn-icon"
                  variant="link"
                  @click="() => {
                    formData.verified_pdf = null
                  }"
                >
                  <feather-icon
                    color="red"
                    size="15"
                    icon="Trash2Icon"
                    class="ml-1"
                  />
                </b-button>
              </span>
              <span v-else>
                <b-form-file
                  v-model="formData.verified_pdf"
                  :disabled="initialLoader || saveLoader ||data.is_closed"
                  accept=".pdf"
                />
              </span>
              <p
                v-if="errorMessage && errorMessage.verified_pdf.length"
                class="text-danger"
              >
                {{ errorMessage.verified_pdf[0] }}
              </p>
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Remark
            </b-th>
            <b-td>
              <b-form-textarea
                v-if="!data.is_closed"
                v-model="formData.remark"
                :disabled="initialLoader || saveLoader"
              />
              <p
                v-else
                class="mb-0"
              >
                {{ formData.remark || '-' }}
              </p>
            </b-td>
          </b-tr>
          <b-tr>
            <b-th> Mark this entry as closed </b-th>
            <b-td>
              <b-form-checkbox
                v-model="formData.is_closed"
                :disabled="initialLoader || saveLoader || data.is_closed"
              />
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-form>

    <template #modal-footer="{cancel}">
      <div class="d-flex">
        <b-button
          :disabled="initialLoader || saveLoader"
          @click="cancel"
        >
          Close
        </b-button>
        <b-button
          v-if="data && !data.is_closed"
          :disabled="initialLoader || saveLoader"
          class="ml-1"
          variant="primary"
          @click="handleSave"
        >
          Save
          <b-spinner
            v-if="saveLoader"
            small
            label="Small Spinner"
          />
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import axios from 'axios'
import {
  BModal, BFormTextarea, BFormGroup, BForm, BButton, BSpinner, BTable, BTableSimple, BFormFile,
  BTbody, BTd, BTr, BTh, BAlert, BFormCheckbox,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import dateMixin from '@/mixins/dateMixin'

export default {
  components: {
    BModal,
    BFormTextarea,
    BFormGroup,
    BForm,
    BButton,
    BSpinner,
    BTable,
    BTableSimple,
    BTbody,
    BTd,
    BTr,
    BTh,
    BFormFile,
    BAlert,
    BFormCheckbox,
  },
  mixins: [dateMixin],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      data: null,
      initialLoader: false,
      initialError: null,
      saveLoader: false,
      errorMessage: null,
      formData: null,
    }
  },
  computed: {
    dialog: {
      get() {
        return this.$props.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      },
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        this.initialLoader = true
        this.initialError = null
        const { data } = await axios.get(`/stocks/verified_stock/${this.$props.id}/`)
        this.data = data
        const { is_closed, remark, verified_pdf } = this.data
        this.formData = {
          is_closed, remark, verified_pdf,
        }
      } catch (error) {
        this.initialError = error?.response?.data?.detail || 'Failed to fetch details!'
      } finally {
        this.initialLoader = false
      }
    },
    async handleSave() {
      try {
        this.errorMessage = null
        this.saveLoader = true
        const payload = new FormData()
        const { remark, verified_pdf: verifiedPdf, is_closed: isClosed } = this.formData
        if (isClosed) {
          payload.set('is_closed', isClosed)
        }

        payload.set('remark', remark)

        if (verifiedPdf && typeof verifiedPdf !== 'string') {
          payload.set('verified_pdf', verifiedPdf)
        } else if (this.data.verified_pdf && !verifiedPdf) {
          payload.set('delete_verified_pdf', true)
        }

        await axios.patch(`/stocks/verified_stock/${this.$props.id}/`, payload)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Data updated successfully!',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.$emit('onUpdate')
      } catch (error) {
        this.errorMessage = error?.response?.data || 'Something Went Wrong!'
      } finally {
        this.saveLoader = false
        this.saveAndCloseLoader = false
      }
    },
  },
}
</script>
