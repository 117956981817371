<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <h4>
          Verified Stock Reports
        </h4>
      </b-card-header>
      <b-card-body>
        <verified-stock-filter v-model="filters" />
        <div
          v-if="loading"
          class="text-center"
        >
          <b-spinner
            label="Small Spinner"
            variant="primary"
          />
        </div>

        <b-alert
          variant="danger"
          :show="loadingError ? true : false"
        >
          <div class="alert-body">
            <p>
              {{ loadingError }}
            </p>
          </div>
        </b-alert>

        <b-table
          v-if="!loading && !loadingError"
          :items="verifiedStockReports"
          :fields="tableFields"
          responsive="sm"
          striped
          hover
          class="compact-table"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          show-empty
          @sort-changed="sortingChanged"
        >
          <template #cell(date)="data">
            {{ formatedDate(data.item.date) }}
          </template>

          <template #cell(excel)="data">
            <a
              v-if="data.item.excel"
              :href="data.item.excel"
              target="_blank"
            >
              <span>View File</span>
            </a>
            <span v-else>-</span>
          </template>

          <template #cell(verified_pdf)="data">
            <a
              v-if="data.item.verified_pdf"
              :href="data.item.verified_pdf"
              target="_blank"
            >
              <span>View File</span>
            </a>
            <span v-else>-</span>
          </template>

          <template #cell(is_closed)="data">
            <status-icon
              :value="`${data.item.is_closed}`"
              hide-label
            />
          </template>

          <template #cell(invoices)="data">
            <span class="text-wrap">
              {{ getParsedData(data.item.invoices).invoices }}
            </span>
          </template>

          <template #cell(suppliers)="data">
            <span class="text-wrap">
              {{ getParsedData(data.item.invoices).suppliers }}
            </span>
          </template>

          <template #cell(actions)="data">
            <feather-icon
              icon="EditIcon"
              size="16"
              @click="handleEditClick(data.item.id)"
            />
          </template>
        </b-table>

        <detailed-pagination
          v-if="!loading && !loadingError"
          :total-items="totalItems"
          :per-page="perPage"
          :current-page="currentPage"
          @page-changed="handlePageChange"
        />
      </b-card-body>
    </b-card>
    <update-verified-report-model
      v-if="selectedId"
      :id="selectedId"
      v-model="dialog"
      @onUpdate="fetchVerifiedReports"
    />
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BTable, BCardBody, BSpinner, BAlert,
} from 'bootstrap-vue'
import DetailedPagination from '@/components/UI/DetailedPagination.vue'
import UpdateVerifiedReportModel from '@/components/Stock/VerifiedStockReport/UpdateVerifiedReportModel.vue'
import axios from 'axios'
import dateMixin from '@/mixins/dateMixin'
import StatusIcon from '@/components/UI/StatusIcon.vue'
import VerifiedStockFilter from '@/components/Stock/VerifiedStockReport/VerifiedStockFilter.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BTable,
    BCardBody,
    DetailedPagination,
    BSpinner,
    BAlert,
    UpdateVerifiedReportModel,
    StatusIcon,
    VerifiedStockFilter,
  },
  mixins: [dateMixin],
  data() {
    return {
      sortBy: 'date',
      sortDesc: true,
      perPage: 50,
      totalItems: 0,
      currentPage: 1,
      tableFields: [
        { key: 'date', label: 'Date', sortable: true },
        { key: 'excel', label: 'Excel File' },
        { key: 'verified_pdf', label: 'Verified PDF' },
        { key: 'invoices', label: 'Invoices' },
        { key: 'suppliers', label: 'Suppliers' },
        { key: 'remark', label: 'Remark' },
        { key: 'is_closed', label: 'Is Closed', sortable: true },
        { key: 'actions', label: 'Actions' },
      ],
      verifiedStockReports: [],
      loading: false,
      loadingError: null,
      selectedId: null,
      dialog: false,
      filters: {
        date: null,
        isClosed: null,
        invoiceNo: null,
        supplier: null,
        productName: null,
      },
    }
  },
  watch: {
    dialog(newVal) {
      if (!newVal) {
        this.selectedId = null
      }
    },
    filters: {
      handler() {
        this.fetchVerifiedReports()
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchVerifiedReports()
  },
  methods: {
    getParsedData(data) {
      const invoiceList = data.map(item => item.invoice_no)
      const supplierList = [...new Set(data.map(item => item.supplier__name))]
      return {
        invoices: invoiceList.join(', '),
        suppliers: supplierList.join(', '),
      }
    },
    handlePageChange(page) {
      this.currentPage = page
      this.fetchVerifiedReports()
    },

    async fetchVerifiedReports() {
      try {
        this.loading = true
        this.dialog = false
        this.loadingError = null

        const {
          isClosed, supplier, invoiceNo, date, productName,
        } = this.filters

        const [fromDate, toDate] = date?.split(' to ') || []

        const { data } = await axios.get('/stocks/verified_stock/', {
          params: {
            per_page: this.perPage,
            page: this.currentPage,
            sort_by: this.sortBy,
            ...(this.sortDesc ? { sort_desc: this.sortDesc } : {}),
            ...(isClosed !== null ? { is_closed: isClosed } : {}),
            ...(supplier ? { supplier } : {}),
            ...(invoiceNo ? { invoice_no: invoiceNo } : {}),
            ...(fromDate && toDate ? { from_date: fromDate, to_date: toDate } : {}),
            ...(productName ? { product_name: productName } : {}),
          },
        })
        this.verifiedStockReports = data.results
        this.totalItems = data.count
      } catch (error) {
        this.loadingError = error?.response?.message || 'Failed to fetch verified stocks!'
      } finally {
        this.loading = false
      }
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy
      this.sortDesc = ctx.sortDesc
      this.fetchVerifiedReports()
    },
    handleEditClick(id) {
      this.selectedId = id
      this.dialog = true
    },
  },

}
</script>
