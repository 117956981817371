<template>
  <b-row class="mb-2">
    <b-col
      cols="12"
      md="4"
      lg="3"
    >
      <b-form-group label="Invoice No">
        <b-form-input
          v-model="invoiceNo"
          type="text"
          placeholder="Search Invoice No"
        />
      </b-form-group>
    </b-col>
    <b-col
      cols="12"
      md="4"
      lg="3"
    >
      <b-form-group label="Supplier">
        <b-form-input
          v-model="supplier"
          type="text"
          placeholder="Search Supplier"
        />
      </b-form-group>
    </b-col>
    <b-col
      cols="12"
      md="4"
      lg="3"
    >
      <b-form-group label="Date">
        <FlatPicker
          v-model="filters.date"
          placeholder="Select Date Range"
          :config="{ mode: 'range'}"
        />
      </b-form-group>
    </b-col>
    <b-col
      cols="12"
      md="4"
      lg="3"
    >
      <b-form-group label="Status">
        <custom-v-select
          v-model="filters.isClosed"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="isClosedOptions"
          label="text"
          value="value"
          placeholder="Select Status"
          :reduce="(data) => data.value"
        />
      </b-form-group>
    </b-col>
    <b-col
      cols="12"
      md="4"
      lg="3"
    >
      <b-form-group label="Product Name">
        <b-form-input
          v-model="productName"
          type="text"
          placeholder="Search Product Name"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import FlatPicker from '@/components/UI/FlatPicker.vue'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import { debounce } from 'lodash'

export default {
  components: {
    BRow, BCol, FlatPicker, CustomVSelect, BFormInput, BFormGroup,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isClosedOptions: [
        { value: true, text: 'True' },
        { value: false, text: 'False' }],
    }
  },
  computed: {
    filters: {
      get() {
        const data = this.$props.value
        return data
      },
      set(newVal) {
        this.$emit('input', newVal)
      },
    },
    invoiceNo: {
      get() {
        return this.filters.invoiceNo
      },
      set(newVal) {
        this.updateFilter({ invoiceNo: newVal })
      },
    },
    productName: {
      get() {
        return this.filters.productName
      },
      set(newVal) {
        this.updateFilter({ productName: newVal })
      },
    },
    supplier: {
      get() {
        return this.filters.supplier
      },
      set(newVal) {
        this.updateFilter({ supplier: newVal })
      },
    },
  },
  methods: {
    updateFilter: debounce(function updateFilter(newFilter) {
      this.$emit('input', {
        ...this.filters,
        ...newFilter,
      })
    }, 500),
  },
}
</script>
